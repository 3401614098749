import { Field, Form, Formik, FormikHelpers } from "formik"
import { useDispatch } from "react-redux"
import { signInSchema } from "../SignInModal/SignInModal.schema"
import Input from "../../../../reusableComponents/Formik/Input"
import CustomErrorMessage from "../../../../reusableComponents/CustomErrorMessage"
import { errorCodes } from "../../../../constants/errorCodes"
import { EmailNotConfirmedErrorMessage } from "../SignInModal/EmailNotConfirmedErrorMessage"
import InputPassword from "../../../../reusableComponents/Formik/InputPassword"
import ButtonPrimary from "../../../../reusableComponents/ButtonPrimary"
import { StyledAdminSignIn } from "./AdminSignIn.styles"
import { adminLoginActions } from "../../../../redux/actions/admin/adminUser/admin"

const initialValues = {
    email: "",
    password: "",
}

type FormValues = {
    email: string
    password: string
    errorCode?: number
}

const AdminSignIn = () => {
    const dispatch = useDispatch()

    const onSubmit = (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        dispatch(adminLoginActions.request({ ...values, setErrors }))
    }

    return (
        <StyledAdminSignIn>
            <Formik
                initialValues={initialValues}
                validationSchema={signInSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, dirty }) => (
                    <Form className="form">
                        <div className="mainContent">
                            <div className="formContent">
                                <div className="fieldMargin block">
                                    <Field
                                        type="email"
                                        name="email"
                                        as={Input}
                                        placeholder="Email"
                                        block
                                    />
                                    <CustomErrorMessage name="email" component="div" />
                                    {errors?.errorCode !== undefined &&
                                        +errors?.errorCode === errorCodes.EMAIL_NOT_CONFIRMED && (
                                            <EmailNotConfirmedErrorMessage email={values.email} />
                                        )}
                                </div>
                                <div className="block">
                                    <Field
                                        type="password"
                                        name="password"
                                        as={InputPassword}
                                        placeholder="Password"
                                        block
                                    />
                                    <CustomErrorMessage name="password" component="div" />
                                </div>
                            </div>
                        </div>
                        <div className="buttonsBlock">
                            <ButtonPrimary disabled={!dirty} type="submit">
                                Submit
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            </Formik>
        </StyledAdminSignIn>
    )
}

export default AdminSignIn
