import styled from "styled-components"

export const StyledAdminSignIn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .buttonsBlock {
        display: flex;
        justify-content: center;
    }
`
